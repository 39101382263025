import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox, videoBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					Memorial Day 2023: Things To Do – Boat Version | Tampa Bay Boating
					Adventures
				</title>
				<meta
					name="description"
					content="Commemorate Memorial Day 2023 with a unique twist. Fom the vibrant Tampa Bay Margarita and Music Festival to exploring the Florida Aquarium, there's so much to do! Float along the scenic Hillsborough River, reflecting on the sacrifices of servicemen and women. Cherish this meaningful day with your loved ones while enjoying the freedom that they fought to protect."
				/>
				<meta name="keywords" content="Memorial Day, Hillsborough River" />
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="cruising university of tampa"
									src="../../images/blog/cruising-university-of-tampa.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>Memorial Day 2023: Things To Do – Boat Version</h1>
									<p>March 04, 2023</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<p>
											Memorial Day is a time set aside to remember the
											sacrifices of the brave servicemen and women, who
											dedicated themselves to our Armed Forces and lost their
											lives in the process. We take this time to pause for
											reflection, remembering their sacrifices and understanding
											the freedom that we have because of them. Most people
											choose to spend Memorial Day with their loved ones around
											them and what better way to spend time with your loved
											ones than by hiring a boat in Tampa Bay and hitting the
											open water. Here are some great ideas to help you plan
											your day:
										</p>
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="armature works from water"
													src="../../images/banners/armature-works-from-water.jpg"
												/>
											</div>
										</div>
										<h2>Visit the Tampa Bay Margarita and Music Festival</h2>
										<p>
											Sail your way through Tampa Bay until you reach Julian B
											Lane Riverfront Park where you will find a great open-air
											music festival for Memorial Day. There are a number of
											public docks available in the area, meaning that you can
											dock and head on foot to check out the live music and
											delicious food and drink offerings, exploring the
											different stages and stalls.
										</p>
										<hr />
										<h2>Spend Some Time At The Florida Aquarium</h2>
										<p>
											One of the best spots in Tampa is the Florida aquarium
											which is packed full of beautiful fish, coral reefs, and
											even penguins! Get up close and personal with some of the
											ocean’s most beautiful creatures in the stingray touch
											tank or have some fun with the family in the outdoor
											splash pad water area. Last year, on Memorial Day, the
											Aquarium offered free entry to anyone currently serving in
											the US armed forces, or any veterans as a way to thank
											them for their service. We expect them to do something
											similar this year so keep an eye on their website for
											details. Dock your boat near the aquarium and spend a
											couple of hours learning about the oceans around us – what
											a great way to spend the day!
										</p>
										<hr />
										<h2>
											Float Down the Hillsborough River Towards the Convention
											Center
										</h2>
										<p>
											One of the most beautiful places to go if you have
											chartered a boat for Memorial Day is along the
											Hillsborough River which moves through downtown Tampa.
											Downtown Tampa has a number of different events that run
											during Memorial Day that you can take in and absorb –
											spend your time floating down the river, taking in all
											that the day has to offer. Just make sure that you reach
											the Convention Center later on in the day to watch the
											beautiful fireworks display which reflects perfectly over
											the water – a great ending to a special day.
										</p>
										<hr />
										<h2>Remember the Reason We Take This Time…</h2>
										<p>
											While it is great to get some time off from work and spend
											it with your friends and family especially if you choose
											to spend it on a boat floating around the bay, it is
											important to not lose the meaning of Memorial Day. Take
											some time out of your day to reflect on the courageous
											efforts of those who stand in the face of danger to
											protect our nation’s freedoms – after all, that is what
											Memorial Day is all about.
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
